import { CssBaseline } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { MakeRoutes, Schema } from "../routing"

const useStyles = makeStyles(() => ({
	"@global": {
		"html, body": {
			padding: 0,
			margin: 0,
		},
		body: {
			minWidth: 320,
			backgroundColor: "#efefef",
			fontFamily: "'Montserrat', sans-serif",
			overflowY: "scroll",
			overflowX: "hidden",
		},
		"#app": {
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			minHeight: "100vh",
		},
		img: {
			maxWidth: "100%",
		},
		"a, p, span, button, input": {
			letterSpacing: "0.04em",
		},
		a: {
			textDecoration: "none",
		},
		p: {
			margin: 0,
		},
		h1: {
			fontFamily: "'Trajan Pro', sans-serif",
		},
		h2: {
			fontFamily: "'Trajan Pro', sans-serif",
		},
		h3: {
			fontFamily: "'Trajan Pro', sans-serif",
		},
		header: {
			flexShrink: 0,
		},
		main: {
			flex: "1 0 auto",
			display: "flex",
			marginTop: "6em",
		},
		footer: {
			flexShrink: 0,
		},
	},
}))

function Root() {
	useStyles()

	return (
		<>
			<CssBaseline />
			<MakeRoutes routes={Schema} />
		</>
	)
}

export default Root
