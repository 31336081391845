import { lazy } from "react";

const App = lazy(() => import("../components/App"));
const Home = lazy(() => import("../components/App/Home"));
const About = lazy(() => import("../components/App/About"));
const News = lazy(() => import("../components/App/News"));

const Schema = [
  {
    path: "/",
    component: App,
    routes: [
      {
        path: "/news",
        exact: true,
        component: News,
      },
      {
        path: "/about",
        exact: true,
        component: About,
      },
      {
        path: "/",
        exact: true,
        component: Home,
      },
    ],
  },
];

export default Schema;
