import React, { Suspense } from "react"
import { createMuiTheme } from "@material-ui/core/styles"
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/styles"
import { create } from "jss"
import { BrowserRouter as Router } from "react-router-dom"
import Root from "./components/Root"

import TrajanProRegular from './assets/fonts/TrajanPro-Regular.ttf';
import TrajanProBold from './assets/fonts/TrajanPro-Bold.otf';

const jss = create({
	plugins: [...jssPreset().plugins],
})

const theme = createMuiTheme({
	palette: {
		primary: { main: "#1278a6", contrastText: "#fff" },
		secondary: { main: "#1278a6", contrastText: "#fff" },
	},
	typography: {
		fontFamily: "'Montserrat', sans-serif",
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				"@font-face": [
					{
						fontFamily: "Trajan Pro",
						fontStyle: "normal",
						fontWeight: 400,
						src: `url(${TrajanProRegular})`,
					},
					{
						fontFamily: "Trajan Pro",
						fontStyle: "italic",
						fontWeight: 800,
						src: `url(${TrajanProBold})`,
					},
				],
			},
		},
	},
})


function App() {
  return (
    <Router>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={null}>
            <Root />
          </Suspense>
        </ThemeProvider>
      </StylesProvider>
    </Router>
  );
}

export default App;
