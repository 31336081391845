import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import NotFound from "../components/NotFound";

function MakeRoutes({ routes }) {
  const location = useLocation();

  if (!routes) {
    return null;
  }

  const routesMap = routes.map(function (route, i) {
    return (
      <Route key={i} path={route.path} exact={Boolean(route.exact)}>
        <route.component {...route} />
      </Route>
    );
  });

  return (
    <Switch location={location}>
      {routesMap}
      <Route component={NotFound} />
    </Switch>
  );
}

export default MakeRoutes;
